import { request2 } from './request'
export function promotions() {
    return request2({
      url: '/promotions'
    })
  }
//自营商品列表
export function products(page=1,size=20) {
    return request2({
        url: '/products',
        params: {
            page,
            size
        }
    })
}
//自营商品搜索信息
export function product_search(key='',page=1,size=20) {
    return request2({
        url: '/products/search',
        params: {
            key,
            page,
            size
        }
    })
}
//自营商品详情
export function productsDetail(id) {
    return request2({
        url: '/products/'+id,
        params: {
            id
        }
    })
}

//自营加入购物车
export function addCart(stock_keeping_unit_id,quantity) {
    return request2({
        url: '/cart_items',
        data: {
            stock_keeping_unit_id,
            quantity
        },
        method:'post',
    })
}

//自营购物车列表
export function cartList() {
    return request2({
        url: '/cart_items',
    })
}

//自营修改购物车商品数量
export function updateCart(id,quantity) {
    return request2({
        url: '/cart_items/'+id,
        data: {
            id,
            quantity
        },
        method:'patch',
    })
}

//自营删除购物车中的商品
export function deleteCart(id) {
    return request2({
        url: '/cart_items/'+id,
        data: {
            id
        },
        method:'delete',
    })
}

//自营地址列表
export function addressList(page=1,size=20) {
    return request2({
        url: '/addresses',
        params: {
            page,
            size
        },
    })
}

//自营创建订单
export function createOrder(address_id,used_cash_coupons=false,used_points_coupons=false) {
    return request2({
        url: '/orders/new',
        params: {
            address_id,
            used_cash_coupons,
            used_points_coupons
        },
    })
}
//自营创建订单
export function createOrderNow(address_id,stock_keeping_unit_id,quantity,used_cash_coupons=false,used_points_coupons=false) {
    return request2({
        url: '/orders/new',
        params: {
            address_id,
            stock_keeping_unit_id,
            quantity,
            used_cash_coupons,
            used_points_coupons
        },
    })
}
//自营订单列表
export function orderList(state) {
    return request2({
        url: '/orders',
        params: {
            state
        },
    })
}

//自营取消订单
export function cancelOrder(id) {
    return request2({
        url: '/orders/'+id+'/cancel',
        data: {
            id
        },
        method: 'put'
    })
}

//自营结算
export function payOrder(address_id,remark) {
    return request2({
        url: '/orders',
        data: {
            address_id,
            remark
        },
        method: 'post'
    })
}
//自营结算
export function payOrderNow(address_id,stock_keeping_unit_id,quantity,remark,invitation_code,used_cash_coupons,used_points_coupons) {
    return request2({
        url: '/orders',
        data: {
            address_id,
            stock_keeping_unit_id,
            quantity,
            remark,
            invitation_code,
            used_cash_coupons,
            used_points_coupons
        },
        method: 'post'
    })
}
//自营订单详情
export function orderDetail(id) {
    return request2({
        url: '/orders/'+id,
        params: {
            id
        },
    })
}

//自营新增地址
export function addAddress(data) {
    return request2({
        url: '/addresses',
        data: data,
        method:'post'
    })
}

//自营省市区
export function districts(parent_id) {
    return request2({
        url: '/districts',
        params: {
            parent_id
        }
    })
}
//自营设置默认地址
export function set_as_default(id) {
    return request2({
        url: '/addresses/'+id+'/set_as_default',
        data: {
            id
        },
        method:'patch'
    })
}
//自营删除地址
export function delAddr(id) {
    return request2({
        url: '/addresses/'+id,
        data: {
            id
        },
        method:'delete'
    })
}
//自营余额支付
export function balance_payments(object_type,object_id,payment_password) {
    return request2({
        url: '/balance_payments',
        data: {
            object_type,
            object_id,
            payment_password
        },
        method:'post'
    })
}
//自营修改地址
export function updateAddr(id,data) {
    return request2({
        url: '/addresses/'+id,
        data: data,
        method:'patch'
    })
}
//自营申请退款列表
export function refunds_new(order_id,order_type='order') {
    return request2({
        url: '/refunds/new',
        params: {
            order_id,
            order_type
        },
    })
}

//自营申请退款原因
export function refund_reasons(need_return=true) {
    return request2({
        url: '/refund_reasons',
        params: {
            need_return
        },
    })
}
//自营发起退款
export function refunds(data) {
    return request2({
        url: '/refunds',
        data: data,
        method:'post'
    })
}
//自营退款详情
export function refundsDetail(id) {
    return request2({
        url: '/refunds/'+id,
        params: {id}
    })
}
//自营确认收货
export function receive(id) {
    return request2({
        url: '/orders/'+ id +'/receive',
        data: {
            id
        },
        method: 'put'
    })
}
//自营撤消退款申请
export function cancelApply(id) {
    return request2({
        url: '/refunds/'+ id +'/cancel',
        data: {
            id
        },
        method: 'patch'
    })
}

//自营编辑申请
export function editApply(id) {
    return request2({
        url: '/refunds/'+id+'/edit',
        data: {
            id
        },
    })
}
//自营保存退货物流信息
export function logistics(refund_id,data) {
    return request2({
        url: '/refunds/'+refund_id+'/logistics',
        data: data,
        method:'put'
    })
}


//跳转链接
export function click_links(tag,link_type) {
    return request2({
        url: '/click_links',
        params: {tag,link_type}
    })
}

